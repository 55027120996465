import Autocomplete from '/js/vendor.js'

const opts = {
  onSelectItem: (e) => {
    document.getElementById("searchGlobal").value = e.label
    document.getElementById("searchGlobal").closest('form').submit()
  },
  activeClasses: ['bg-white', 'text-dark'],
  fullWidth: true,
  showAllSuggestions: true,
  highlightTyped: true,
  fixed: true,
  noCache: true,
};
new Autocomplete(document.getElementById("searchGlobal"), opts);
document.getElementById("searchGlobal").addEventListener('keydown', async (ev) => {
  if (ev.which == 13) {
    document.getElementById("searchGlobal").closest('form').submit()
    //if (document.querySelector('.autocomplete-menu.show') === null) {
    //}
  }
})

const optsOffcanvas = {
  onSelectItem: (e) => {
    document.getElementById("searchGlobalOffcanvas").value = e.label
    document.getElementById("searchGlobalOffcanvas").closest('form').submit()
  },
  activeClasses: ['bg-white', 'text-dark'],
  fullWidth: true,
  showAllSuggestions: true,
  highlightTyped: true,
  fixed: true,
  noCache: true,
};
new Autocomplete(document.getElementById("searchGlobalOffcanvas"), optsOffcanvas);
document.getElementById("searchGlobalOffcanvas").addEventListener('keydown', async (ev) => {
  if (ev.which == 13) {
    document.getElementById("searchGlobalOffcanvas").closest('form').submit()
    //if (document.querySelector('.autocomplete-menu.show') === null) {
    //}
  }
})
